import React from 'react';
import { Link as CLink, LinkProps as CLinkProps } from '@chakra-ui/react';
import cx from 'classnames';
import { useTheme } from '../../theme';
import { typographyStylesFromTokens } from './text';
import { cartesianProps } from '../../utils/cartesian-props';
import { Box, BoxProps } from './box';
import { ArrowRight, ArrowSquareOut } from '@mezzoforte/forge-icons';

type LinkStyles = Omit<CLinkProps, 'children'>;
const styles: Record<'base', LinkStyles> = {
  base: {
    fontSize: 'md',
    lineHeight: 'normal',
    color: 'primary',
  },
};

export const linkVariants = ['default', 'action', 'external'] as const;
type Variant = (typeof linkVariants)[number];
type LinkButtonSizes = 'default' | 'default-hero' | 'default-lists';

export interface LinkProps extends Omit<CLinkProps, 'as'> {
  /**
   * Link style
   */
  variant?: Variant;
  /**
   *  If `true`, the link will open in new tab
   */
  isExternal?: boolean;
  /**
   * Get typography from parent
   */
  inheritTypography?: boolean;
  /**
   * Set height of a Button
   */
  withButtonSize?: LinkButtonSizes;
  as?: BoxProps['as'];
  to?: string | Record<string, unknown>;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      variant = 'default',
      children,
      isExternal: propIsExternal,
      as = 'a',
      inheritTypography = false,
      withButtonSize,
      className,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const isExternal = variant === 'external' || propIsExternal;
    const propsForExternal = isExternal
      ? { target: '_blank', rel: 'noreferrer noopener' }
      : {};
    const stylesWithButtonSize = withButtonSize
      ? {
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: forgeTokens.styles?.button?.[withButtonSize]?.minHeight,
        }
      : {};

    return (
      <CLink
        ref={ref}
        {...typographyStylesFromTokens(forgeTokens, 'text').base}
        {...stylesWithButtonSize}
        {...props}
        className={cx('forge-link', 'forge-link--reset', className)}
        {...styles.base}
        {...(inheritTypography && inheritStyles)}
        color={forgeTokens.colors.brand}
        as={as as CLinkProps['as']}
        {...propsForExternal}
      >
        {variant !== 'default' && variant !== 'external'
          ? variantChildren({ children, variant })
          : ifExternal({
              children,
              isExternal,
            })}
      </CLink>
    );
  }
);

const inheritStyles = {
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  letterSpacing: 'inherit',
};

const variantChildren = ({
  children,
  variant,
}: {
  children: React.ReactNode;
  variant?: Variant;
}) => (
  <Box as="span">
    {children}
    {variant === 'action' && (
      <Box
        as="span"
        display="inline-flex"
        verticalAlign="text-bottom"
        marginLeft={1}
        paddingBottom="1px"
      >
        <ArrowRight size={16} />
      </Box>
    )}
  </Box>
);

const ifExternal = ({
  children,
  isExternal,
}: {
  children: React.ReactNode;
  isExternal?: boolean;
}) =>
  isExternal ? (
    <Box as="span">
      {children}
      <Box
        as="span"
        display="inline-flex"
        verticalAlign="text-bottom"
        marginLeft={1}
        paddingBottom="1px"
      >
        <ArrowSquareOut size={16} />
      </Box>
    </Box>
  ) : (
    children
  );

/**
 * For testing
 */

const components = cartesianProps<LinkProps>(
  {
    variant: ['default', 'action', 'external'],
    withButtonSize: ['default', 'default-hero', 'default-lists'],
    isTruncated: [true, false],
    inheritTypography: [true, false],
    children: ['Testing'],
    onClick: [() => null],
  },
  Link
);

export const toTesting = <>{components}</>;
